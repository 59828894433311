
import { defineComponent, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { MaintainCellProps, MaintainColProps, MaintainChildren } from "./types";
import { ColmunsProps, ColmunsChildrenProps } from "@/API/types";
import { getCalculatorOperation, updateState } from "@/API/finance";
import { message } from "ant-design-vue";
import { BuItem } from "@/views/Approve/SalesRoleApprove/ProgramHome/types";
import { getBrand, getSubscribedBu } from "@/API/approve";
import useTableHeight from "@/hooks/useTableHeight";
import { findColumnIdBytitle } from "@/utils";
import { useStore } from "vuex";
interface StatusParams {
  typeClassCode: string;
  modelYear: string;
  model: string;
  nstGroupName: string;
}

interface OperationParams {
  status: string;
  brands: string[];
  sheetType: string;
  selectBrandId: string;
}
export default defineComponent({
  name: "Calculator Confirm",
  setup() {
    const store = useStore();
    const route = useRoute();
    const { tableHeight } = useTableHeight(false);

    // 当选中项发生变化
    const selectedKeys = ref();
    // 配置选择框
    const rowSelection = {
      onChange: (selectedRowKeys: []) => {
        selectedKeys.value = selectedRowKeys;
      },
      // hideDefaultSelections: true,
    };

    // 获取children中的数据，进行配置
    const deployChild = (
      arr: MaintainChildren[],
      type?: string
    ): ColmunsChildrenProps[] => {
      const children: ColmunsChildrenProps[] = [];
      arr.forEach((item: MaintainChildren) => {
        if (type === "fixed") {
          if (item.display === "Model") {
            children.push({
              title: item.display,
              dataIndex: item.id,
              key: item.id,
              width: route.params.brand !== "MB" ? 230 : 150,
              align: "center",
            });
          } else {
            children.push({
              title: item.display,
              dataIndex: item.id,
              key: item.id,
              width: 130,
              align: "center",
            });
          }
        } else {
          children.push({
            title: item.display,
            dataIndex: item.id,
            key: item.id,
            slots: { customRender: item.id },
            width: 240,
            align: "center",
          });
        }
      });
      return children;
    };

    const columns = ref<ColmunsProps[]>([]);
    const configColumns = (data: MaintainColProps[]) => {
      columns.value = [];
      data.forEach((item: MaintainColProps, index: number) => {
        //判断是否是第一个 最后一个，并是否有中间滚动的部分
        if (index === 0 && data.length > 3) {
          columns.value.push({
            title: item.display,
            fixed: "left",
            children: deployChild(item.children, "fixed"),
          });
        } else if (index === data.length - 1 && data.length > 3) {
          columns.value.push({
            title: item.display,
            fixed: "right",
            children: deployChild(item.children, "fixed"),
          });
        } else if (index === 0) {
          columns.value.push({
            title: item.display,
            children: deployChild(item.children, "fixed"),
          });
        } else if (index === data.length - 1) {
          columns.value.push({
            title: item.display,
            children: deployChild(item.children, "fixed"),
          });
        } else {
          columns.value.push({
            title: item.display,
            children: deployChild(item.children),
            align: "center",
          });
        }
        if (data.length === 2) {
          columns.value.splice(1, 0, {
            title: "",
            dataIndex: "",
          });
        }
      });
    };

    // 获取sheetName
    const sheetName: string[] = [];
    const findSheetName = (data: MaintainColProps[]) => {
      data.forEach((item, index) => {
        if (index !== 0 && index !== data.length - 1) {
          sheetName.push(item.display);
        }
      });
    };

    // 获取表格的数据
    const colArr = ref<string[]>([]);
    // let brands: string[] = []
    const dataSource = ref();
    const getTableData = () => {
      const params = store.state.finance.stateParmas;
      // if (route.params.type === 'unconfirm') {
      //     // 获取 comfig的数据
      //     params.status = 'unconfirm'
      // } else {
      //     // Re open 的数据
      //     params.status = 'confirm'
      // }
      getCalculatorOperation({ params }).then((res) => {
        //根据id排序
        res.maintain.tableCol.sort((x: any, y: any) => {
          return x.id - y.id;
        });
        console.log(
          store.state.finance.stateParmas.brand,
          "store.state.finance.stateParmas.brand"
        );
        if (store.state.finance.stateParmas.brand !== "MB") {
          dataSource.value = res.maintain.tableCell.filter(
            (item) => item.id !== item.parentId
          );
        } else {
          dataSource.value = res.maintain.tableCell.filter(
            (item) => item.id === item.parentId
          );
        }
        if (Array.isArray(dataSource.value) && dataSource.value.length > 0)
          colArr.value = Object.keys(dataSource.value[0]);
        findSheetName(res.maintain.tableCol);
        // 配置columns
        configColumns(res.maintain.tableCol);
      });
    };

    // 初始化数据
    const init = async () => {
      // 1. 获取当前用户的bu
      // const buList = await getSubscribedBu()
      // 2. 获取bu id的集合
      // brands = await fetchFindBrandByBu(getBuidList(buList))
      getTableData();
    };

    // 获取选中的参数
    const getParams = (): StatusParams[] => {
      const params: StatusParams[] = [];
      const arr = dataSource.value.filter((item: MaintainCellProps) =>
        selectedKeys.value.includes(item.id)
      );
      arr.forEach((item: MaintainCellProps) => {
        params.push({
          typeClassCode: item[findColumnIdBytitle("Type Class", columns.value)],
          modelYear: item[findColumnIdBytitle("Model Year", columns.value)],
          model: item[findColumnIdBytitle("Model", columns.value)],
          nstGroupName:
            item[findColumnIdBytitle("NST Group Name", columns.value)] || "",
        });
      });
      return params;
    };
    // 点击按钮确认更改状态
    const visibleTip = ref(false);
    const changeStatus = () => {
      visibleTip.value = true;
    };

    // 更改当前状态的时候
    const onChangeStatus = () => {
      const configParams = store.state.finance.stateParmas;
      console.log("getParams()", getParams());
      if (getParams().length > 0) {
        updateState(configParams, getParams()).then(() => {
          visibleTip.value = false;
          getTableData();
        });
      } else {
        message.error("请选择至少一项数据");
      }
    };

    onMounted(() => {
      init();
    });
    return {
      tableHeight,
      columns,
      rowSelection,
      dataSource,

      changeStatus,
      visibleTip,
      onChangeStatus,
      colArr,
      selectedKeys,
    };
  },
});
